/* eslint-disable @next/next/no-page-custom-font */
import { AppProps } from "next/app";
import Head from "next/head";
import { Global, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { theme } from "ui";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useViewportSize } from "@mantine/hooks";

const setVh = (vh: number) => {
  document.documentElement.style.setProperty("--vh", vh + "px");
};

export default function App(props: AppProps) {
  const { height } = useViewportSize();

  const { Component, pageProps } = props;
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  useEffect(() => {
    setVh(height);
  }, [height]);

  return (
    <>
      <Head>
        <title>Raw Query</title>
        <meta
          name="description"
          content="Query your data using natural language"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link rel="icon" href="/images/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
          rel="stylesheet"
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <GoogleOAuthProvider
            clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string}
          >
            <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
              <NotificationsProvider>
                <Global
                  styles={(theme) => ({
                    "*, *::before, *::after": {
                      boxSizing: "border-box",
                    },

                    body: {
                      margin: 0,
                      padding: 0,
                      ...theme.fn.fontStyles(),
                      lineHeight: theme.lineHeight,
                      maxHeight: "var(--vh, 100vh)",
                      minHeight: "var(--vh, auto)",
                    },

                    html: {
                      maxHeight: "-webkit-fill-available",
                    },
                  })}
                />
                {/* @ts-ignore */}
                <Component {...pageProps} />
              </NotificationsProvider>
            </MantineProvider>
          </GoogleOAuthProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}
