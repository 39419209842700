import type { MantineTheme } from "@mantine/core";
import { DEFAULT_THEME } from "@mantine/core";

export const theme: MantineTheme = {
  ...DEFAULT_THEME,
  fontFamily: "Lato, sans-serif",
  headings: {
    ...DEFAULT_THEME.headings,
    fontFamily: "Lato, sans-serif",
  },
  breakpoints: {
    xs: 500,
    sm: 800,
    md: 1000,
    lg: 1200,
    xl: 1400,
  },
  black: "#222",
  primaryColor: "blue",
};
